import React from 'react';
import logo from '../../assets/images/logo.png';

const forgotPassword = () => {
  return (
    <div class="auth-wrapper">
        <div class="auth-content">
            <div class="auth-bg">
                <span class="r"></span>
                <span class="r s"></span>
                <span class="r s"></span>
                <span class="r"></span>
            </div>
            <div class="card">
                <div class="card-body text-center">
                    <div class="mb-4">
                        {/* <i class="feather icon-unlock auth-icon"></i> */}
                        <img style={{width: '100px'}} src={logo} alt="" />
                    </div>
                    <h3 class="mb-4">Forgot Password</h3>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Enter Phone Number" />
                    </div>
                    <button class="btn btn-primary shadow-2 mb-4">Reset Password</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default forgotPassword;